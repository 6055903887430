<template>
  <section class="yml">
    <div class="f mb-20 w">
      <div>
        <h1>Ubuntu 18</h1>
        <p>Инструкция как настроить Ubuntu 18</p>
      </div>
      <a class="btn" target="_blank" href="https://cp.sprinthost.ru/customer/boxes/list">Открыть хостинг</a>
    </div>
    <div class="fields w">
      <div class="form-control">
        <label>IP Address</label>
        <input v-model="ip" />
      </div>
      <div class="form-control">
        <label>Username</label>
        <input spellcheck="false" v-model="username" />
      </div>
    </div>
    <div v-for="(commandNodes, cN) of commands" :key="cN" class="action_result" tabindex="0">
      <template v-for="(node, n) of commandNodes">
        <pre :key="`${n}-${node.type}`" v-if="node.type === 'code'" v-highlightjs="node.content"><code class="bash"></code></pre>
        <p :key="`${n}-${node.type}`" v-if="node.type === 'text'" v-html="node.content" />
      </template>
    </div>
    <div class="row">
      <p class="next">Перейти к <router-link to="/services/node_js">настройке бекенда</router-link></p>
      <p class="next">Перейти к <router-link to="/services/nginx">настройке доменов</router-link></p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Deployment',
  data() {
    return {
      ip: '185.185.68.125',
      username: 'yablonev',
    }
  },
  computed: {
    commands() {
      return [
        [
          { type: 'code', content: `ssh root@${this.ip}` },
        ],
        [
          { type: 'code', content: `apt update` },
        ],
        [
          { type: 'code', content: `apt upgrade` },
        ],
        [
          { type: 'code', content: `apt dist-upgrade` },
        ],
        [
          { type: 'code', content: `apt autoremove` },
        ],
        [
          { type: 'code', content: `adduser ${this.username}` },
        ],
        [
          { type: 'code', content: `adduser ${this.username} sudo` },
        ],
        [
          { type: 'code', content: `nano /etc/ssh/sshd_config` },
          { type: 'text', content: 'И там ставим PermitRootLogin no' },
        ],
        [
          { type: 'code', content: `systemctl restart sshd` },
        ],
        [
          { type: 'code', content: `exit` },
        ],
        [
          { type: 'code', content: `ssh ${this.username}@${this.ip}` },
        ],
        [
          { type: 'code', content: `mkdir -p ~/.ssh` },
        ],
        [
          { type: 'code', content: `nano ~/.ssh/authorized_keys` },
        ],
        [
          { type: 'text', content: '<span style="color: #4dbfbf">Local Terminal</span>' },
          { type: 'code', content: `cat ~/.ssh/id_rsa.pub | pbcopy` },
        ],
        [
          { type: 'code', content: `sudo chmod -R 700 ~/.ssh/` },
        ],
        [
          { type: 'code', content: `sudo systemctl restart sshd` },
        ],
        [
          { type: 'code', content: `sudo nano /etc/ssh/sshd_config` },
          { type: 'text', content: 'И там ставим PasswordAuthentication no' },
        ],
        [
          { type: 'code', content: `sudo systemctl restart sshd` },
        ],
        [
          { type: 'code', content: `sudo visudo` },
          { type: 'text', content: 'И там ставим %sudo   ALL=(ALL:ALL) NOPASSWD:ALL' },
        ],
        [
          { type: 'code', content: `sudo apt install ufw` },
        ],
        [
          { type: 'code', content: `sudo apt-add-repository -y ppa:hda-me/nginx-stable` },
        ],
        [
          { type: 'code', content: `sudo apt install brotli nginx nginx-module-brotli` },
        ],
        [
          { type: 'code', content: `sudo systemctl unmask nginx.service` },
        ],
        [
          { type: 'code', content: `sudo nano /etc/nginx/nginx.conf`}
        ],
        [
          { type: 'text', content: `Там раскомментируем в верхней части файла` },
          { type: 'code', content: `load_module modules/ngx_http_brotli_filter_module.so;` },
          { type: 'code', content: `load_module modules/ngx_http_brotli_static_module.so;` },
        ],
        [
          { type: 'text', content: 'Вставляем конфиг для Brotli вместо того что есть' },
          { type: 'code', content: `    # Brotli
      brotli on;
      brotli_comp_level 6;

      brotli_types
        text/xml
        image/svg+xml
        application/x-font-ttf
        image/vnd.microsoft.icon
        application/x-font-opentype
        application/json
        font/eot
        application/vnd.ms-fontobject
        application/javascript
        font/otf
        application/xml
        application/xhtml+xml
        text/javascript
        application/x-javascript
        text/$;` },
        ],
        [
          { type: 'code', content: `sudo nano /etc/ufw/applications.d/nginx.ini` },
          { type: 'text', content: 'Туда ставим ↓' },
          { type: 'code', content: `[Nginx HTTP]
title=Web Server
description=Enable NGINX HTTP traffic
ports=80/tcp

[Nginx HTTPS]
title=Web Server (HTTPS)
description=Enable NGINX HTTPS traffic
ports=443/tcp

[Nginx Full]
title=Web Server (HTTP,HTTPS)
description=Enable NGINX HTTP and HTTPS traffic
ports=80,443/tcp` },
        ],
        [
          { type: 'code', content: `sudo ufw enable` },
          { type: 'text', content: 'Нажать кнопку <span class="accent">«y»</span>' },
        ],
        [
          { type: 'code', content: `sudo ufw allow 'Nginx Full'` },
        ],
        [
          { type: 'code', content: `sudo ufw allow 'OpenSSH'` },
        ],
        [
          { type: 'code', content: `sudo apt install software-properties-common` },
        ],
        [
          { type: 'code', content: `sudo add-apt-repository ppa:certbot/certbot`},
          { type: 'text', content: 'И Enter' },
        ],
        [
          { type: 'code', content: 'sudo apt install python-certbot-nginx' },
          { type: 'text', content: 'И нажать кнопку <span style="color: #62f3d8">«y»</span>' },
        ],
        [
          { type: 'code', content: 'sudo openssl dhparam -out /etc/ssl/certs/dhparam.pem 2048' },
        ],
        [
          { type: 'code', content: 'sudo mkdir -p /etc/nginx/snippets/' },
        ],
        [
          { type: 'code', content: 'sudo nano /etc/nginx/snippets/ssl-params.conf' },
          { type: 'text', content: 'Туда ставим ↓' },
          { type: 'code', content: `ssl_session_timeout 1d;
ssl_session_cache shared:SSL:10m;
ssl_session_tickets off;

ssl_dhparam /etc/ssl/certs/dhparam.pem;

ssl_protocols TLSv1.2 TLSv1.3;
ssl_ciphers ECDHE-ECDSA-AES128-GCM-SHA256:ECDHE-RSA-AES128-GCM-SHA256:ECDHE-ECDSA-AES256-GCM-SHA384:ECDHE-RSA-AES256-GCM-SHA384:ECDHE-ECDSA-CHACHA20-POLY1305:ECDHE-RSA-CHACHA20-POLY1305:DHE-RSA-AES128-GCM-SHA256:DHE-RSA-AES256-GCM-SHA384;
ssl_prefer_server_ciphers off;

add_header Strict-Transport-Security 'max-age=63072000' always;` }
        ],
        [
          { type: 'code', content: 'sudo timedatectl set-timezone UTC' },
        ],
        [
          { type: 'code', content: 'sudo mkdir /var/www' },
        ],
        [
          { type: 'code', content: 'cd /var/' },
          { type: 'code', content: 'sudo chmod 777 *' },
          { type: 'text', content: 'Если не нужен Backend, тут можно остановится :)' },
        ],
      ]
    }
  },
  methods: {
    async getIP() {
      const data = await fetch('https://api.ipify.org?format=json')
      const json = await data.json()
      return json.ip
    }
  },
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/pages/nginx'

.action_result:focus, .action_result:focus-visible
  box-shadow: inset 0 0 8px 2px #000000c2
  border: 0

.action_result p
  color: #4dbf90
  font-size: 14px
  line-height: 28px
  padding-left: 0.3em
</style>